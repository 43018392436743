import PropTypes from 'prop-types'
import { useEffect } from 'react'
import { ThemeProvider } from 'styled-components'
import axios from 'axios'
import App from 'next/app'
import isEmpty from 'lodash/isEmpty'

import GlobalStyle from '../components/GlobalStyle'

import { theme } from '../theme'
import { AuthProvider } from '../context/AuthContext'
import { GlobalProvider } from '../context/GlobalContext'

// styled-components will cause re-requests of fonts: https://github.com/styled-components/styled-components/issues/1593
// load them once for the app here
import '../public/fonts.css'
import Header from '../components/header/Header'
import Footer from '../components/footer/Footer'
import SEO from '../components/SEO'
import { createClient } from '../utils/prismic'
import EmergencyBanner from '../components/EmergencyBanner'
import { interpolate } from '../utils/interpolate'
import LaunchDarklyProvider from '../context/LDContext'

const MyApp = (props) => {
  const { Component, globalSiteData, hasLead, hasLoan, pageProps, user, realTimeData } = props

  useEffect(() => {
    // UIE-4266: handle links contain 'happymoney.com' should not translate
    const linkElms = document.getElementsByTagName('a')
    for (const linkElm of linkElms) {
      if (linkElm.textContent.includes('happymoney.com')) {
        linkElm.classList.add('notranslate')
      }
    }
  })

  return (
    <ThemeProvider theme={theme}>
      <AuthProvider
        value={{
          hasLoan,
          hasLead,
          user,
        }}
      >
        <GlobalProvider value={{
          ...(pageProps.globalSiteData ? pageProps.globalSiteData : globalSiteData),
          ...(pageProps.realTimeData ? pageProps.realTimeData : realTimeData)
        }}
        >
          <SEO
            title={pageProps?.data?.data?.seo_title || pageProps?.data?.seo_title || globalSiteData?.seo_title}
            description={pageProps?.data?.data?.seo_description || pageProps?.data?.seo_description || globalSiteData?.seo_description}
            social={!isEmpty(pageProps?.data?.data?.image) ? pageProps?.data?.data?.image : isEmpty(pageProps?.data?.seo_image) ? globalSiteData?.seo_social : pageProps?.data?.seo_image}
          />
          <GlobalStyle />
          <Header
            partnerInfo={pageProps?.partnerInfo}
          />
          <main
            aria-label="main"
            style={{
              position: 'relative',
              zIndex: 1
            }}
          >
            <EmergencyBanner />
            <Component {...pageProps} />
          </main>

          <Footer />
        </GlobalProvider>
      </AuthProvider>
    </ThemeProvider>
  )
}

MyApp.getInitialProps = async (appContext) => {
  const client = createClient()

  const appProps = await App.getInitialProps(appContext)
  const poCookie = appContext?.ctx?.req?.cookies?._po_
  let pageProps = {}

  if (appContext?.Component?.getInitialProps) {
    pageProps = await appContext.Component.getInitialProps(appContext.ctx)
  }

  const { getPageData } = require("../utils/getPageData") // eslint-disable-line

  const globalSiteData = await getPageData({ uid: 'global-site-data' })

  let realTimeData,
      templateVarArr
  // realTimeData wont fetch if all page are static (using getStaticProps)
  try {
    ({ data: realTimeData } = await client.getSingle('real-time-data'))
  } catch (error) {
    realTimeData = null
  }

  try {
    ({ data: { template_variables: templateVarArr } } = await client.getSingle('template-variables'))
  } catch (error) {
    templateVarArr = []
  }

  const parsedGlobalSiteData = await interpolate(templateVarArr, globalSiteData)
  if (poCookie) {
    try {
      const {
        data: { hasLoan, hasLead, user },
      } = await axios.post(`${ process.env.NEXT_PUBLIC_HM_POINT_BREAK_URL }/api/auth`, { poCookie })

      return {
        ...appProps,
        pageProps,
        hasLoan,
        hasLead,
        user,
        globalSiteData: parsedGlobalSiteData,
        realTimeData
      }
    } catch (error) {
      return {
        ...appProps,
        pageProps,
        globalSiteData: parsedGlobalSiteData,
        realTimeData
      }
    }
  }

  return {
    ...appProps,
    pageProps,
    globalSiteData: parsedGlobalSiteData,
    realTimeData
  }
}

export function reportWebVitals(metric) {
  if (typeof document !== 'undefined') {
    // eslint-disable-next-line
    const { loggerClient } = require("../utils/loggerClient")

    switch (metric.name) {
      case 'FCP':
        loggerClient.info('point-break web vital - FCP', {
          metric,
        })
        break
      case 'LCP':
        loggerClient.info('point-break web vital - LCP', {
          metric,
        })
        break
      case 'CLS':
        loggerClient.info('point-break web vital - CLS', {
          metric,
        })
        break
      case 'FID':
        loggerClient.info('point-break web vital - FID', {
          metric,
        })
        break
      case 'TTFB':
        loggerClient.info('point-break web vital - TTFB', {
          metric,
        })
        break
      default:
        break
    }
  }
}

MyApp.defaultProps = {
  data: [],
  hasLead: false,
  hasLoan: false,
  pageProps: {},
  user: {},
  globalSiteData: {},
  realTimeData: null
}

MyApp.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.any]).isRequired,
  hasLead: PropTypes.bool,
  hasLoan: PropTypes.bool,
  pageProps: PropTypes.oneOfType([PropTypes.object]),
  router: PropTypes.oneOfType([PropTypes.object]).isRequired,
  user: PropTypes.oneOfType([PropTypes.object]),
  globalSiteData: PropTypes.oneOfType([PropTypes.object]),
  realTimeData: PropTypes.oneOfType([PropTypes.object])
}

export default LaunchDarklyProvider(MyApp)
