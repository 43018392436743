import Image from 'next/image'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import DOMPurify from 'isomorphic-dompurify'
import PropTypes from 'prop-types'

import Text from './Text'

const TopBanner = ({ message, bannerBgColor }) => {
  const [isBannerShown, setIsBannerShown] = useState(false)

  useEffect(() => {
    // compare banner message
    const currentMessage = sessionStorage.getItem('top-banner-message')
    const newMessage = JSON.stringify(message)
    if (currentMessage !== newMessage) {
      sessionStorage.setItem('top-banner-message', newMessage)
      sessionStorage.setItem('closed-top-banner', 'false')
    }

    if (sessionStorage.getItem('closed-top-banner') !== 'true') {
      setIsBannerShown(true)
    }
  }, [message])

  const handleClick = () => {
    setIsBannerShown(false)
    window.sessionStorage['closed-top-banner'] = true
  }

  return (
    <Wrapper
      className={isBannerShown ? '' : 'collapsed'}
      data-testid="top-banner"
      bgColor={bannerBgColor}
    >
      <StyledText>
        <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }} />
      </StyledText>
      <CloseButton data-testid="banner-close-button" onClick={handleClick}>
        <Image src="/close-button.svg" width={15} height={15} alt="banner close button" />
      </CloseButton>
    </Wrapper>)
}

const CloseButton = styled.div`
  position: absolute;
  top: 13px;
  right: 20px;
  cursor: pointer;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    right: 27px;
  }
`

const Wrapper = styled.div`
  width: 100%;
  max-height: 200px;
  background-color: ${ ({ theme, bgColor }) => bgColor || theme.colors.azureLight };
  border-bottom: 1px solid ${ (props) => props.theme.colors.black };

  @media screen and (prefers-reduced-motion: reduce) {
&.collapsed {
    max-height: 0;
    overflow: hidden;
    border-bottom: none;
    transition: none;

    ${ CloseButton } {
      display: none;
    }
}
}

  &.collapsed {
    max-height: 0;
    overflow: hidden;
    border-bottom: none;
    transition: max-height 0.5s cubic-bezier(0, 1, 0, 1);
    ${ CloseButton } {
      display: none;
    }
  }
`

const StyledText = styled(Text)`
  padding: 14px 50px;
  margin: 0 auto;
  text-align: center;

  @media screen and (max-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
    padding: 18px 40px;
    font-size: 10px;
  }

  a {
    white-space: nowrap;
  }
`

TopBanner.propTypes = {
  message: PropTypes.string.isRequired,
  bannerBgColor: PropTypes.string.isRequired,
}

export default TopBanner
