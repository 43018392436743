import * as Prismic from '@prismicio/client'

// Prismic API endpoint
export const apiEndpoint = process.env.PRISMIC_POINT_BREAK_URL

// Access Token if the repository is not public
// Generate a token in your dashboard and configure it here if your repository is private
export const accessToken = process.env.PRISMIC_TOKEN

const urlMap = {
  'all-lending-partners': 'all-lending-partners',
  'arcus-end-user-terms': 'arcus-end-user-terms',
  'authorization-for-automatic-payments': 'authorization-for-automatic-payments',
  'borrower-bank-account-verification': 'borrower-bank-account-verification',
  'method-end-user-terms': 'method-end-user-terms',
  'direct-card-payoff-disclosures': 'direct-card-payoff-disclosures',
  buildup: 'buildup',
  'california-privacy-notice': 'california-privacy-notice',
  careers: 'careers',
  'consumer-report-authorization': 'consumer-report-authorization',
  'direct-card-payoff': 'direct-card-payoff',
  'error-resolution': 'error-resolution',
  'esign-consent': 'esign-consent',
  'getting-approved': 'getting-approved',
  homepage: '',
  'lending-licenses': 'lending-licenses',
  'lending-partners': 'lending-partners',
  payoff: 'payoff',
  'payoff-loan': 'payoff-loan',
  'platform-terms-of-use': 'platform-terms-of-use',
  'privacy-lobby-page': 'privacy',
  'privacy-notice': 'privacy-notice',
  'privacy-policy': 'privacy-policy',
  'rates-and-terms': 'rates-and-terms',
  'terms-of-use': 'terms-of-use',
  'trust-and-security': 'trust-and-security',
  assistance: 'assistance',
  company: 'company',
  contact: 'contact',
  press: 'press',
  sweepstakes: 'sweepstakes',
}

export const createClient = ({ req, previewData, options } = {}) => {
  const endpoint = Prismic.getRepositoryEndpoint('point-break')
  const client = Prismic.createClient(endpoint, {
    // Add token for private repos
    accessToken: '',
    ...options,
  })

  if (req) {
    client.enableAutoPreviewsFromReq(req)
  } else if (previewData) {
    client.queryContentFromRef(previewData.ref)
  }

  return client
}

// Maps the url links to internal Prismic documents
export const linkResolver = (doc) => {
  if (doc.uid.includes('dm-')) {
    return `/${ doc.uid.replace('dm-', '') }`
  }
  if (doc.uid.includes('dm_dynamic_')) {
    return `/${ doc.uid.replace('dm_dynamic_', '') }`
  }
  if (doc.uid.includes('cu-')) {
    return `/${ doc.uid.replace('cu-', '') }`
  }
  if (doc.uid.includes('affiliate-')) {
    return `/affiliate/${ doc.uid.replace('affiliate-', '') }`
  }

  const url = urlMap[doc.uid]

  if (url) {
    return `/${ url }`
  }

  if (doc.type === 'blog') {
    return `/articles/${ doc.uid }`
  }

  return '/'
}

// Additional helper function for Next/Link component
export const hrefResolver = (doc) => {
  if (doc.type === 'page') {
    return '/[uid]'
  }

  return '/'
}
